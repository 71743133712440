import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby";

import Seo from "../components/seo"
import Layout_page from "../components/layout_page";

export default function ThanksPage() {

  return (


    <div className="bg-def_grey_1 page_formatting">

      <Layout_page>

          <h1 className="mt-20 text-white">Tack för att du kontaktar oss!</h1>
          <p className="text-neutral-300">Ditt svar är registrerat.</p>
          <Link to="/" className="button_1 mt-10" href="/">Gå till Hem</Link>

      </Layout_page>
    </div>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Mötesprotokoll" />
